





























































import Vue from "vue";
import gql from "graphql-tag";
import Trimlevel from "./Trimlevel";
import Headline from "@/components/Headline.vue";
import FormSection from "@/components/FormSection.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import UploadInput from "@/components/form/UploadInput.vue";
import SaveBar from "@/components/form/SaveBar.vue";
import SingleSelectInput from "@/components/form/SingleSelectInput.vue";

export default Vue.extend({
  apollo: {
    pdb_trimlevels_by_pk: {
      // GraphQL Query
      query: gql`
        query pdb_trimlevels_by_pk($id: bigint!) {
          pdb_trimlevels_by_pk(id: $id) {
            id
            limited_edition
            name
            online
            tag
            modeltype_id
          }
        }
      `,
      // Reactive variables
      variables() {
        return {
          id: this.$route.params.trimlevelID,
        };
      },
      skip() {
        // Skip request if no model id is provided in creation mode
        return this.skipQuery;
      },
      update(data) {
        return data.pdb_trimlevels_by_pk;
      },
      result({ data, loading }) {
        if (!loading) {
          console.log(data);
          this.pdb_trimlevel = data.pdb_trimlevels_by_pk;
        }
      },
    },
    pdb_modeltypes: {
      query: gql`
        query pdb_modeltypes {
          pdb_modeltypes {
            id
            name
          }
        }
      `,
      update(data) {
        return data.pdb_modeltypes;
      },
    },
  },
  data() {
    return {
      skipQuery: true,
      pdb_trimlevel: {} as Trimlevel,
    };
  },
  computed: {
    selectedModeltypeID: {
      get(): string {
        return `${this.pdb_trimlevel.modeltype_id}`;
      },
      set(newVal: string) {
        const newID = parseInt(newVal);
        this.pdb_trimlevel.modeltype_id = newID;
      },
    },
  },
  created() {
    // Get model if model id is provided
    if (this.$route.params.trimlevelID) {
      this.skipQuery = false;
    }
  },
  methods: {
    store() {
      // clone current model object and remove __typename property which was added by apollo
      const objectToStore: Trimlevel = Object.assign({}, this.pdb_trimlevel);
      delete objectToStore.__typename;
      console.log(objectToStore);

      if (!objectToStore.id) {
        // Create new model
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation($objects: [pdb_trimlevels_insert_input!]!) {
                insert_pdb_trimlevels(objects: $objects) {
                  affected_rows
                }
              }
            `,
            // Parameters
            variables: {
              objects: objectToStore,
            },
          })
          .then((data) => {
            // Result
            console.log(data);
            this.$router.push("/trimlevels");
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
          });
      } else {
        // Update model
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation(
                $set: pdb_trimlevels_set_input
                $pk: pdb_trimlevels_pk_columns_input!
              ) {
                update_pdb_trimlevels_by_pk(_set: $set, pk_columns: $pk) {
                  id
                }
              }
            `,
            // Parameters
            variables: {
              set: objectToStore,
              pk: { id: objectToStore.id },
            },
          })
          .then((data) => {
            // Result
            console.log(data);

            this.$router.push("/trimlevels");
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
          });
      }
    },
    cancel() {
      this.$router.push("/trimlevels");
    },
  },
  components: {
    Headline,
    FormSection,
    TextInput,
    CheckboxInput,
    UploadInput,
    SaveBar,
    SingleSelectInput,
    NumberInput,
  },
});
